import { graphql } from 'gatsby'
import React from 'react'

import { IndexQueryQuery } from '../../../types/graphql-types'
import Meta from '../../components/meta/meta'
import Layout from '../../components/layout/layout'
import LightboxWrapper from '../../components/lightbox/lightboxwrapper'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const UserGuidePage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta

  return (
    <Layout edition="02-2021" location={location}>
      <Meta site={meta} />
      <div className="content-inner shadow-lg">
        <div
          className="page-header"
          style={{
            backgroundImage: 'url(../../img/user-guide-page-header.jpg)',
          }}
        ></div>
        <article className="post">
          <h1>User Guide Page : Upgrade</h1>

          <div className="row">
            <div className="col-md-6 mb-3">
              <p>
                The user guide page has been upgraded to better serve our
                consumers helping them to find as fast as possible the right
                information they are are looking for. This new page count on a
                new search that allows the user to enter a calibre, marketing
                reference or even just a collection name. They can also find the
                information by nice a simple watch visuals that have as
                information a PDF, explanation videos for some of them.
              </p>
            </div>
            <div className="col-md-4 offset-md-2 mb-3">
              <LightboxWrapper>
                <a href={'../../img/user-guide-mobile.jpg'}>
                  <img
                    src={'../../img/user-guide-mobile.jpg'}
                    className="img-fluid thumbnail"
                  />
                </a>
              </LightboxWrapper>
            </div>
          </div>

          <LightboxWrapper>
            <a href={'../../img/user-guide-1.jpg'}>
              <img
                src={'../../img/user-guide-1.jpg'}
                className="img-fluid thumbnail"
              />
            </a>
          </LightboxWrapper>
          <LightboxWrapper>
            <a href={'../../img/user-guide-2.jpg'}>
              <img
                src={'../../img/user-guide-2.jpg'}
                className="img-fluid thumbnail"
              />
            </a>
          </LightboxWrapper>

          <div className="spacer"></div>

          <div className="row align-items-center justify-content-center">
            <div className="col-md-8">
              <div className="alert alert-light text-primary shadow">
                <div className="d-flex align-items-center">
                  <ion-icon
                    name="rocket-outline"
                    style={{ marginRight: '30px', fontSize: '36px' }}
                  ></ion-icon>
                  <div>
                    <h3>Go live ?</h3>
                    <p className="mb-0">Q3 2021</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="spacer"></div>
          <hr />
          <div className="spacer"></div>

          <h3>Later in the year</h3>
          <p>
            The customer will also have access to a new feature: a digital
            animated user guide usable directly on every device. No need to
            download a file, fully controlable by the user to find exactly what
            they need right away !
          </p>

          <LightboxWrapper>
            <a href={'../../img/user-guide-comming-soon.jpg'}>
              <img
                src={'../../img/user-guide-comming-soon.jpg'}
                className="img-fluid thumbnail"
              />
            </a>
          </LightboxWrapper>

          <div className="spacer"></div>

          <div className="row align-items-center justify-content-center">
            <div className="col-md-8">
              <div className="alert alert-light text-primary shadow">
                <div className="d-flex align-items-center">
                  <ion-icon
                    name="rocket-outline"
                    style={{ marginRight: '30px', fontSize: '36px' }}
                  ></ion-icon>
                  <div>
                    <h3>Go live ?</h3>
                    <p className="mb-0">Q1 2022</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <blockquote>
            <div className="d-flex flex-column flex-md-row">
              <img
                src={'../../img/steven-valente.jpg'}
                className="rounded-circle shadow-lg mr-3"
                style={{ width: '80px', height: '80px' }}
              />
              <div>
                <h4>Need further information?</h4>
                <p>
                  Please email{' '}
                  <a href="mailto:steven.valente@longines.com">
                    steven.valente@longines.com
                  </a>{' '}
                  and he will be glad to answer your questions and organize a
                  Skype call at your convenience.
                </p>
              </div>
            </div>
          </blockquote>
        </article>
      </div>
    </Layout>
  )
}

export default UserGuidePage

export const pageQuery = graphql`
  query UserGuidePage_02_2021 {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
